/* Start::app-header */
.app-header {
  max-width: 100%;
  height: 4.688rem;
  z-index: 100;
  position: fixed;
  inset-block-start: 0;
  inset-inline: 0;
  background: $header-bg;
  border-block-end: 1px solid $header-border-color;
  transition: all 0.1s ease;

  #mainHeaderProfile {
    color: $header-prime-color;
  }
}

@media (min-width: 992px) {
  .app-header {
    padding-inline-start: 16.875rem;
  }
}

.header-icon-badge {
  position: absolute;
  padding: 0.2rem 0.3rem;
  inset-block-start: 1.1rem;
  inset-inline-end: 0.45rem;
  font-size: 0.625rem;
}

.source-logo2 {
  font-size: 1.69rem;
  width: 1.45rem;
  fill: $white;
  transition: all ease 0.2s;
}

.source-logo {
  font-size: 1.69rem;
  width: 1.45rem;
  fill: $default-text-color;
  transition: all ease 0.2s;
}

.source-title {
  color: $text-muted;
  font-size: 0.8rem;
  transition: all ease 0.3s;
}

.related-app {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-color: $header-border-color;
  border: 1px solid transparent;
  border-radius: 7px;
  padding: 0.5rem 0rem;
  text-align: center;
  border-radius: $default-radius;
  transition: all ease 0.3s;

  &:hover {
    .source-logo {
      fill: $primary;
      color: $primary;
      transform: scale(1.2);
    }
    .source-title {
      color: $primary;
    }
    border-color: $primary;
  }
}

.header-profile-dropdown {
  min-width: 11rem;

  a:hover {
    svg {
      fill: $primary;
    }
  }
}

/* End::app-header */

/* Start::main-header-container */
.main-header-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  padding: 0 1.5rem;

  .header-content-left,
  .header-content-right {
    display: flex;
    align-items: stretch;
  }

  .header-element {
    display: flex;
    align-items: stretch;
  }

  .header-link {
    display: flex;
    align-items: center;
    padding: 1rem 0.65rem;

    &:hover,
    &.show {
      .profile-heading,
      .header-link-icon {
        fill: $primary;
        color: $primary;
        background: transparent;
      }
    }
  }

  .header-link-icon {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1.25rem;
    color: $header-prime-color;
    fill: $header-prime-color;
    transition: all ease 0.05s;
    position: relative;
    padding: 0.25rem;

    &:hover {
      fill: $primary;
      color: $primary;
    }
  }

  .dropdown-toggle {
    text-decoration: none;

    &::after {
      content: none;
    }
  }

  .main-profile-user {
    .dropdown-menu {
      width: 13rem;
    }

    .dropdown-item {
      font-weight: 400;
      font-size: 0.813rem;
      color: $default-text-color;
      padding: 1.25rem 1rem;
      height: 2.375rem;
      display: flex;
      align-items: center;

      &:hover {
        color: $primary;

        i {
          color: $primary;
          opacity: 1;
        }
      }
    }
  }

  .main-header-dropdown {
    box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.3);
    inset-block-start: -3px !important;
    &.header-shortcuts-dropdown {
      width: 21rem;
    }
    .dropdown-item {
      padding: 0.65rem;
      &:last-child {
        border-block-end: 0;
      }
    }
  }

  .cart-dropdown .main-header-dropdown {
    width: 21rem;
  }

  .notifications-dropdown .main-header-dropdown {
    width: 21rem;
  }
  .message-dropdown .main-header-dropdown {
    width: 21rem;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
}

[dir="rtl"] {
  .sidemenu-toggle {
    .open-toggle {
      transform: rotate(180deg);
    }
  }

  .header-icon-badge {
    inset-inline-end: 28px;
  }
  .pulse-custom {
    inset-inline-end: 10px;
  }
}

/* End::main-header-container */

/* Start::Header-dropdown */
.header-product-item {
  padding-inline-start: 0;

  li {
    position: relative;
    list-style-type: none;
    font-size: 0.75rem;
    font-weight: 400;
    color: $text-muted;
    padding-inline-end: 0.938rem;
  }

  li:nth-child(2),
  li:nth-child(3),
  li:nth-child(4) {
    &:before {
      position: absolute;
      content: "";
      inset-inline-start: -0.438rem;
      width: 1px;
      inset-block-start: 0.25rem;
      height: 0.625rem;
      background-color: $text-muted;
      opacity: 0.1;
    }
  }
}

.header-cart-remove {
  .ti {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    margin-inline-start: 0.75rem;
    color: $danger;
  }

  &:hover {
    .ti {
      background-color: rgba(var(--danger-rgb), 0.1);
    }
  }
}

/* End::Header-dropdown */

/* Start::header-search */

.header-search {
  margin: 17px 0;
  position: relative;
  width: 355px;
  max-width: 355px;

  .form-control {
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    border: 1px solid $header-border-color;
    background: $header-bg;
    color: $header-prime-color;
  
    &:focus {
      border-color: $input-border;
      background: $header-bg;
    }
  }
  
}

.header-search-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  fill: $header-prime-color;
  color: $header-prime-color;
  margin-block-start: -29px;
  margin-inline-start: 19.7rem;
}

/* End::header-search */

/* Start::header-country-selector */
.country-selector {
  .header-link img {
    width: 24px;
    height: 16px;
    padding: 0;
  }

  .dropdown-menu {
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}

/* End::header-country-selector */

/* Start:header dropdowns scroll */
#header-shortcut-scroll,
#header-notification-scroll,
#header-cart-items-scroll,
#header-message-items-scroll {
  max-height: 20rem;
}

/* End:header dropdowns scroll */

/* Start::header badge pulse */
.pulse {
  display: block;
  cursor: pointer;
  animation: pulse-secondary 2s infinite;

  &.pulse-secondary {
    box-shadow: 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }
}

@-webkit-keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
  }
}

@keyframes pulse-secondary {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0);
    box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
  }
}

/* End::header badge pulse */

/* Start::Header theme-mode icon style */
[data-theme-mode="light"] {
  .layout-setting .dark-layout {
    display: none;
  }

  .layout-setting .light-layout {
    display: block;
  }
}

.layout-setting .dark-layout {
  display: none;
}

.layout-setting .light-layout {
  display: block;
}

[data-theme-mode="dark"] {
  .layout-setting .light-layout {
    display: none;
  }

  .layout-setting .dark-layout {
    display: block;
  }
}

/* End::Header theme-mode icon style */

/* Start::Header fullscreen responsive */
@media (max-width: 767.98px) {
  .header-element.header-fullscreen {
    display: none;
  }
}

/* End::Header fullscreen responsive */

/* Start::Responsive header dropdowns */
@media (max-width: 575.98px) {
  .app-header {
    .dropdown-menu {
      width: 100%;
    }
  }
}

/* End::Responsive header dropdowns */

/* Start::toggle */
.sidemenu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $header-prime-color;
  z-index: 1;
  transition: padding-left 0.3s ease;
  padding: 0 9px;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  inset-inline-end: 7px;

  &::before {
    content: "\e907";
    transition: all 0.25s;
    font-size: 21px;
    font-family: "feather";
  }

  &:hover {
    color: $primary;
  }
}
/* End::toggle */

/* Start::header notification dropdown */
.header-notification-text {
  max-width: 14.5rem;
}
/* Start::header notification dropdown */

.pulse-custom {
  display: block;
  position: absolute;
  inset-block-start: 22px;
  inset-inline-end: 15px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $success;
}

.pulse-custom:before {
  content: "";
  inset-block-start: -4px;
  inset-inline-end: -4px;
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background: $success;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(var(--succcess-rgb), 0.4);
  animation: pulse-custom 2s linear infinite;
}
@keyframes pulse-custom {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  8% {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    transform: scale(0.1);
    opacity: 1;
  }
  30% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}
