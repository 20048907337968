
/* Start::Client Page */
.client-cards {
    .client-card {
      .client-card-top {
        display: flex;
        align-items: start;
  
        div {
          &:nth-child(2) {
            a {
              font-size: 1rem;
              font-weight: 500;
              color: $dark;
  
              &:hover {
                color: rgba(var(--dark-rgb), 0.85);
              }
            }
             p {
              margin-block-end: 0;
              color: $text-muted;
             }
          }
        }
  
        .btn {
          padding: .35rem .75rem !important;
        }
      }
  
      .client-card-details {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-block-start: 1rem;
  
        div {
          &:first-child {
            padding-inline-end: 1rem;
            border-inline-end: 1px solid $default-border;
  
            h6 {
              color: $text-muted;
              font-size: 1rem;
              font-weight: normal;
              margin-block-end: 0;
  
              span {
                margin-inline-start: 0.65rem;
                color: $dark;
                font-size: 0.8375rem;
                font-weight: normal;
              }
            }
          }
          &:last-child {
            margin-inline-start: 1rem;
  
            h6 {
              color: $text-muted;
              font-size: 1rem;
              font-weight: normal;
              margin-block-end: 0;
  
              span {
                margin-inline-start: 0.65rem;
                color: $dark;
                font-size: 0.8375rem;
                font-weight: normal;
              }
            }
          }
        }
      }
  
      .social {
        margin: 0 auto;
  
        ul {
          padding: 0;
          margin: 0;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
  
          li {
            padding-inline: 7px;
            a {
              padding: 7px !important;
            }
          }
        }
      }
    }
}

.client-create-card {
    .card-footer {
        padding: 1.5rem !important;
    }
}
@media screen and (max-width:400px) {
  .client-card-top {
    display: block !important;
  }
}
  /* End::Client Page */