.inbox-item-badge {
  display: block;
  width: 10px !important;
  height: 10px !important;
  margin-inline-end: 0.5rem;
  border-radius: 50%;
}

.custom-data-icon {
  td i {
    vertical-align: middle;
    color: $gray-4;
  }
  .star-hover i:hover {
    color: $warning;
  }
  .bm-hover i:hover {
    color: $danger;
  }

  .msg-id {
    font-weight: 500;
    font-size: 0.8375rem;
  }
  .msg {
    color: $custom-black2;
    font-weight: 400;
    font-size: 0.8375rem;
  }
  .msg-time {
    color: $custom-black2;
    font-weight: 500;
    font-size: 0.8375rem;
  }
  .unread {
    font-weight: 600;
  }
}

.mail-dropdown {
  .dropdown-menu {
    min-width: 12rem;
    padding: 5px 0;
  }
  .dropdown-item {
    color: $default-text-color;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 16px;
  }
}

.mail-chat-wish {
  h6{
    font-weight: bold;
    font-size: 0.85rem;
  }
  p {
    margin-block-end: 14px;
  }
}

.reply-icon {
  width: 1.2rem !important;
  color: $gray-5;
  fill: $gray-5;
}

.email-img  {
  h6 {
    font-size: 0.85rem;
    font-weight: normal;
  }
  small {
    font-size: 11px;
  }
  img {
    max-width: 100%;
  }
}

.card-body {
  padding: 24px;
}

.main-content-label {
  color: #1d212f;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.2px;
}

.form-horizontal {
  .custom-form {
    align-items: center;
  }
}

.file-list {
  padding: 1.5rem !important;
  ul {
    padding: 1rem;
  }

  .relation-list {
    li {
      margin-block-end: 0.5rem;

      &:last-child {
        margin-block-end: 0;
      }

      a {
        padding: 0.25rem;
        font-size: .875rem;
        font-weight: normal;
        display: flex;
        align-items: center;
      }
    }
  }
}

.read-mail {
  .more-btn:focus {
    color: var(--button-text-light)!important;
    background-color: rgb(var(--light-rgb))!important;
    border-color: rgb(var(--light-rgb))!important;
  }
}
@media (min-width: 576px) {
  .authentication-btn-group {
    .btn-group {
      display: flex !important;
    }
  }
}


.mail-security-settings {
  .choices__inner {
    min-width: 153px;
  }
}
@media (max-width: 575.98px) {
  #account-settings {
    .btn-group label {
      font-size: .625rem;
    }
  }
}
