/* Timeline */
/*--- Vertical --*/

.vtimeline {
	list-style: none;
	padding: 0;
	position: relative;
	margin-block-end: 20px;
  
	&:before {
	  inset-block-start: 0;
	  inset-block-end: 0;
	  position: absolute;
	  content: " ";
	  width: 2px;
	  background-color: $default-border;
	  inset-inline-start: 50%;
	  margin-inline-start: -1.5px;
	}
  
	.timeline-wrapper {
	  display: block;
	  margin-block-end: 20px;
	  position: relative;
	  width: 100%;
	  padding-inline-end: 90px;
  
	  &:before {
		content: " ";
		display: table;
	  }
  
	  &:after {
		content: " ";
		display: table;
		clear: both;
	  }
  
	  .timeline-panel {
		  padding: 20px;
		  position: relative;
		  background: $gray-1;
		  border-radius: 5px;
		  box-shadow: none;
		  width: 35%;
		  margin-inline-start: 15%;
  
		&:before {
		  position: absolute;
		  inset-block-start: 0;
		  width: 100%;
		  height: 2px;
		  content: "";
		  inset-inline-start: 0;
		  inset-inline-end: 0;
		}
  
		&:after {
		  position: absolute;
		  inset-block-start: 25px;
		  inset-inline-end: -14px;
		  display: inline-block;
		  border-block-start: 14px solid transparent;
		  border-inline-start: 14px solid $default-border;
		  border-inline-end: 0 solid $default-border;
		  border-block-end: 14px solid transparent;
		  content: " ";
		}
  
		.timeline-title {
		  font-size: 1rem;
		  font-weight: 700;
		  margin: 0 0 .625rem;
		}
  
		.timeline-body p + p {
		  margin-block-start: 5px;
		}
	  }
	}
  }
  
  .vtimeline .timeline-wrapper {
	.timeline-panel {
	  .timeline-body ul {
		margin-block-end: 0;
	  }
  
	  .timeline-footer span {
		font-size: .6875rem;
	  }
	}
  
	.timeline-badge {
	  width: 45px;
	  height: 45px;
	  position: absolute;
	  inset-block-start: 16px;
	  inset-inline-start: calc(50% - 24px);
	  z-index: 0;
	  border-start-end-radius: 50%;
	  border-start-start-radius: 50%;
	  border-end-end-radius: 50%;
	  border-end-start-radius: 50%;
	  border: 0px solid $white;
	  border-radius: 50%;
  
	  i {
		  color: $white;
		  text-align: center;
		  font-size: 20px;
		  line-height: 1.8;
		  display:flex;
		  margin: 0 auto;
		  justify-content: center;
		  inset-inline-start: 0;
		  inset-inline-end: 0;
		  padding: 1px 9px;
	  }
	}
  
	&.timeline-inverted {
	  padding-inline-end: 0;
	  padding-inline-start: 90px;
  
	  .timeline-panel {
		margin-inline-start: auto;
		margin-inline-end: 15%;
  
		&:after {
		  border-inline-start-width: 0;
		  border-inline-end-width: 14px;
		  inset-inline-start: -14px;
		  inset-inline-end: auto;
		}
	  }
	}
  }
  
  .timeline-wrapper-primary {
	.timeline-panel:before, .timeline-badge {
	  background: $primary;
	}
  }
  
  .timeline-wrapper-secondary {
	.timeline-panel:before, .timeline-badge {
	  background: #eb6f33;
	}
  }
  
  .timeline-wrapper-success {
	.timeline-panel:before, .timeline-badge {
	  background: $success;
	}
  }
  
  .timeline-wrapper-info {
	.timeline-panel:before, .timeline-badge {
	  background: #01b8ff;
	}
  }
  
  .timeline-wrapper-warning {
	.timeline-panel:before, .timeline-badge {
	  background: $warning;
	}
  }
  
  .timeline-wrapper-danger {
	.timeline-panel:before, .timeline-badge {
	  background: $danger;
	}
  }
  
  .timeline-wrapper-light {
	.timeline-panel:before, .timeline-badge {
	  background: #f1f2f9;
	}
  }
  
  .timeline-wrapper-dark {
	.timeline-panel:before, .timeline-badge {
	  background: $gray-1;
	}
  }
  
  @media (max-width: 992px) {
	.timeline .btn {
	  font-size: 12px;
	}
  
	.vtimeline {
	  &:before {
		inset-inline-start: 26px !important;
	  }
  
	  .timeline-wrapper {
		.timeline-badge {
		  inset-inline-start: 0 !important;
		}
  
		padding-inline-end: 0 !important;
  
		.timeline-panel {
		  width: auto !important;
		  margin-inline-start: 16% !important;
  
		  &:after {
			border-inline-end: 14px solid $gray-1 !important;
			border-inline-start: 0 solid $gray-1 !important;
			inset-inline-start: -14px !important;
			inset-inline-end: auto !important;
			inset-block-start:25px;
		  }
		}
  
		&.timeline-inverted {
		  padding-inline-start: 0 !important;
  
		  .timeline-panel {
			margin-inline-end: 0 !important;
			width: auto !important;
		  }
		}
	  }
	}
  
	.profile-user .p-text {
	  display: none;
	}
  }
  
  /*latetes news timeline */
  
  .latest-timeline-1 ul.timeline-1 {
	list-style-type: none;
	position: relative;
  
	&:before {
	  content: ' ';
	  position: absolute;
	  inset-block-start: 0;
	  inset-block-end: 0;
	  inset-inline-start: 20px;
	  border-inline-start: 2px dotted #c0ccda;
	}
  
	> li {
	  a {
		color: $black;
	  }
  
	  .date {
		font-size: 10px;
	  }
  
	  p {
		font-size: 13px;
	  }
  
	  margin: 25px 0;
	  padding-inline-start: 0.90rem;
  
	  .product-icon {
		width: 40px;
		height: 40px;
		text-align: center;
		border-radius: 50%;
		position: absolute;
		inset-inline-start: 1px;
		background-color: #f3f6f7;
		font-size: 20px;
		line-height: 37px;
		font-size: 21px;
	  }
  
	  &:nth-child(2):before {
		border: 3px solid #fb1c52;
	  }
  
	  &:nth-child(3):before {
		border: 3px solid #e9b307;
	  }
  
	  &:nth-child(4):before {
		border: 3px solid #4481eb;
	  }
  
	  &:last-child:before {
		border: 3px solid #27af06;
	  }
	}
  }
  
  .projects-stat {
	.table-bordered {
	  th, td {
		border: transparent;
	  }
	}
  
	.table {
	  th, td {
		padding: 7px 7px !important;
		vertical-align: baseline;
	  }
	}
  
	.project-names h6 {
	  width: 38px;
	  height: 38px;
	  border-radius: 50%;
	  color: $white;
	  line-height: 2.5;
	  margin-block-end: 0;
	  font-weight: 600;
	  padding: 3px;
	}
  }
  
  .review-project {
	.project-contain {
	  display: inline-block;
	}
  
	.table {
	  th, td {
		vertical-align: middle;
		padding: 7px 15px;
	  }
	}
  
	&.card tbody > tr > {
	  th:not(:first-child), td:not(:first-child) {
		font-family: roboto;
		font-size: 13px !important;
	  }
	}
  
	img {
	  vertical-align: top;
	  width: 35px;
	  min-width: 32px;
	  height: 35px;
	}
  }
  
  .latest-tasks {
	.tasks {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-align: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-box-pack: justify;
	  -ms-flex-pack: justify;
	  justify-content: space-between;
	  position: relative;
	  margin: 0 0 1.15rem 0;
  
	  .span {
		margin-inline-start: 1.3rem;
		font-weight: 500;
		color: $gray-1;
	  }
	}
  
	.task-line:before {
	  position: absolute;
	  display: block;
	  width: .2rem;
	  inset-block-start: .2rem;
	  content: "";
	  border-radius: .2rem;
	  height: 2rem;
	}
  
	.label {
	  color: $default-text-color;
	  margin-inline-start: 1.3rem;
	  font-weight: 500;
	}
  
	.time {
	  margin-inline-start: 1.3rem;
	  font-size: 13px;
	  color: $gray-1;
	}
  
	.task-line {
	  &.primary:before {
		background: $primary;
	  }
  
	  &.pink:before {
		background: $pink;
	  }
  
	  &.success:before {
		background: $success;
	  }
  
	  &.teal:before {
		background: $teal;
	  }
  
	  &.warning:before {
		background: $warning;
	  }
  
	  &.orange:before {
		background: $orange;
	  }
  
	  &.info:before {
		background: $info;
	  }
  
	  &.purple:before {
		background: $purple;
	  }
  
	  &.danger:before {
		background: $danger;
	  }
	}
  
	.check-box .ckbox span {
	  &:before {
		content: '';
		width: 16px;
		height: 16px;
		background-color: rgba(190, 206, 255, 0.05);
		border: 1px solid $default-border;
		inset-block-start: 1px;
		inset-inline-start: 0;
		border-radius: 2px;
	  }
  
	  &:after {
		border-radius: 2px;
	  }
	}
  
	.nav-tabs .nav-link {
	  &.active, &:hover, &:focus {
		color: $primary;
		border:0;
	  }
  
	  color: $gray-1;
	}
  }
  
  .image-grouped img {
	margin-inline-end: -.6em !important;
  }
  
  .background-text {
	position: relative;
	z-index: 10;
  }
  
  .project-countdown {
	#launch_date {
	  margin: 0;
	}
  
	.countdown {
	  padding: 0;
	  margin: 0;
  
	  li {
		width: 65px;
		height: 65px;
		z-index: 0;
		padding: 0px;
		line-height: 30px;
	  }
  
	  .number {
		font-size: 20px;
		font-weight: 700;
		line-height: 14px;
		padding-block-start: 11px;
	  }
  
	  .time {
		padding-block-start: 0px;
		font-size: 11px !important;
	  }
  
	  span {
		display: block;
		color: $white;
	  }
  
	  li {
		background: rgb(40, 92, 247);
		border: 5px solid rgb(40, 92, 247);
	  }
	}
  }
  
  @media (min-width: 992px) {
	.project-countdown .countdown li {
	  margin: 2px 5px 7px 5px;
	}
  }
  
  .Activity-scroll {
	max-height: 349px;
  }
  
  .activity .added-project {
	font-weight: 700;
	font-size: 12px;
	color: $primary;
  }
  
  .sparkline .tooltip {
	width: 20px;
	height: 20px;
  }
  
  .Project-scroll {
	max-height: 367px;
	position: relative;
  }
  
  .rating-scroll {
	max-height: 344px;
	position: relative;
  }
  
  .coming-events {
	.icon {
	  height: 47px;
	  width: 47px;
	  border-radius: 50%;
	  text-align: center;
  
	  span {
		display: block;
		font-size: 12px;
	  }
  
	  .date {
		line-height: 10px;
		padding-block-start: 11px;
	  }
  
	  .month {
		font-size: 11px;
	  }
	}
  
	.pb-3 {
	  padding-block-end: 0.8rem !important;
	}
  
	.pt-3 {
	  padding-block-start: 0.8rem !important;
	}
  }
  
  .activity {
	position: relative;
	padding: 1.25rem 1.25rem 0 1.25rem;
  }
  
  .activity-list {
	position: relative;
  
	&:before {
	  content: ' ';
	  border: 1px solid #eaf2f9;
	  margin: 20px 20px 0 22px;
	  display: inline-block;
	  position: absolute;
	  width: 2px;
	  height: 100%;
	  z-index: 0;
	}
  }
  
  .activity {
	.img-activity {
	  width: 42px;
	  height: 42px;
	  text-align: center;
	  line-height: 34px;
	  border-radius: 50%;
	  position: absolute;
	  -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
	  box-shadow: 0 0 0 0.5px #f8f9fa;
	}
  
	.item-activity {
	  margin-inline-start: 60px;
	  margin-block-end: 22px;
	}
  }
  .timeline-image{
	border-radius:50%;
    width: 45px;
  }

  .timeline-body {
    img {
        max-width: 100%;
    }
  }

  @media (max-width: 576px){
	  .vtimeline .timeline-wrapper .timeline-panel {
		  width: auto !important;
		  margin-inline-start: 24% !important;
	  }
}

[data-theme-mode="dark"] {
	.timeline-panel {
		background-color: $white-2 !important;
	}
}