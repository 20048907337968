.input-group-text {
    border: 0;
    font-size: 0.875rem;
    border-radius: 0.3125rem;
    background-color:$primary-02;
    color: $primary;
    letter-spacing: 0;
    .form-control {
        border: 0;
        border-start-start-radius: 0;
        border-end-start-radius: 0;
    }
}