.main-content-app {
  height: calc(100vh - 220px);
}
.chat-content {
  max-height: calc(100vh - 26rem);
}
.main-content-left-chat {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.main-img-user {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.dot-label {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-inline-end: 3px;
}
.bg-success {
  background-color: $success;
}
.bg-green {
  background-color: $primary;
  color: $white;
}
.avatar-status {
  position: absolute;
  inset-inline-end: -2px;
  inset-block-end: -2px;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid $white;
  border-radius: 50%;
}
.chat-username {
  font-size: 11px;
  width: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  margin-block-end: 0;
}

.chat-custom {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: center;
  border-block-end: 1px solid $default-border;

  &:hover {
    background-color: $primary-01;
  }
}

.chat-active {
  background-color: $primary-01;
}

.chat-hover:hover,
.active-chat {
  background-color: $primary-01;
}

[data-theme-mode="dark"] {
  .chat-hover:hover,
  .active-chat {
    background-color: $white-2;
  }

  .chat-custom {
    p {
      color: $default-text-color !important;
    }
  }
}

.main-chat-header {
  padding: 1rem;
  display: flex;
  border-block-end: 1px solid $default-border;
}
.card.custom-card .card-footer.main-chat-footer {
  background: $custom-white !important;
}

.main-chat-time {
  display: block;
  position: relative;
  text-align: center;
  margin: 20px 0;
  span {
    position: relative;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--text-muted);
  }
}
.main-chat-time::before {
  content: "";
  position: absolute;
  width: 40% !important;
  height: 1px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  background-color: $default-border;
  inset-block-start: 10px;
}
.main-chat-time::after {
  content: "";
  position: absolute;
  width: 40% !important;
  height: 1px;
  inset-inline-start: auto;
  inset-inline-end: 0;
  background-color: $default-border;
  inset-block-start: 10px;
}

.main-chat-footer {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 4.75rem;
  padding: 0 1.5rem;
  border-block-start: 1px solid var(--default-border);
  background-color: var(--custom-white);
  position: absolute;
  inset-inline-start: auto;
  inset-inline-end: auto;
  inset-block-end: 0;
}

.chat-contacts-title,
.chat-network-title {
  padding-inline: 1.5rem;
  padding-block: 1rem;
  border-block-end: 1px solid $default-border;
  display: flex;
  font-weight: 700;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
.media {
  margin-block-start: 20px;
  display: flex;
  align-items: flex-start;
}
.media-body-right {
  margin-inline-end: 20px;
  margin-inline-start: 50px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  overflow: visible;
  vertical-align: top;

  span {
    font-size: 11px;
    color: $category-name-color;
    font-weight: 400;
  }
}
.main-msg-wrapper-right {
  background-color: $primary-02 !important;
  color: $primary !important;
  position: relative;
  border-radius: 20px;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  display: inline-block;
  margin-block-end: 10px;
  font-size: 13px;
  padding: 10px 15px;
}
.media-body-left {
  margin-inline-end: 50px;
  margin-inline-start: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: visible;
  vertical-align: top;

  span {
    font-size: 11px;
    color: $category-name-color;
    font-weight: 400;
  }
}
.main-msg-wrapper-left {
  background-color: $gray-2;
  color: $black;
  position: relative;
  border-radius: 20px;
  border-start-start-radius: 0;
  border-end-end-radius: 0;
  display: inline-block;
  margin-block-end: 10px;
  font-size: 13px;
  padding: 10px 15px;
}

[data-theme-mode="dark"] {
  .main-msg-wrapper-left,
  .main-msg-wrapper-right {
    background-color: $white-2;
    color: $black-8;
  }
}
