.bradius {
	border-radius: 25%;
}
.cover-image {
	background-size: cover !important;
}
.avatar-list {
	margin: 0 0 -.5rem;
	padding: 0;
	font-size: 0;
	.avatar {
		margin-block-end: .5rem;
		margin-inline-end: .5rem;
	}
}
.avatar-list-stacked .avatar {
	margin-inline-end: -.8em !important;
}
.project-cards{
  .project-card-avatar {
    width: 25px !important;
    fill: $white !important;
  }
  .op-67 {
    opacity: 67% !important;
  }
}

.project-card-title a:hover {
    color: $primary !important;
}

.custom-btns {
  .btn {
    padding: 0.3rem 0.8rem;
  }
}

.project-list-table-container {
    .project-list-table {
        table {
            tbody {
                tr {
                    td {
                        &:nth-child(1) {
                            color: $text-muted;
                            font-size: 15px;
                            font-weight: 500;
                            text-align: center;
                        }
                        &:nth-child(2) {
                            font-size: 14px;
                            font-weight: 500;
                        }
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(6),
                        &:nth-child(8) {
                            color: $text-muted;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width:576px) {
    .project-list-table-container,
    .files-table-container,
    .billing-table-container,
    .tickets-table-container,
    .timetracking-table-container,
    .tasks-table-container,
    .project-members-container {
        .table-entries-count {
            width: 17% !important;
        }
        .table-entries-search {
            width: 20% !important;
        }
    }
}

@media(max-width:576px) {
    .project-list-table-container,
    .files-table-container,
    .billing-table-container,
    .tickets-table-container,
    .timetracking-table-container,
    .tasks-table-container,
    .project-members-container {
        .table-entries-count {
            width: 50% !important;
        }
        .table-entries-search {
            width: 75% !important;
        }
    }
}

.project-status::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #59adec;
    inset-block-start: 50px;
    inset-inline-start: 88px;
    transform: translateY(-50%) scale(1);
    animation: expand 1.5s infinite;

    @keyframes expand {
        0% {
            transform: translateY(-50%) scale(1);
        }
        50% {
            transform: translateY(-50%) scale(1.2);
        }
        100% {
            transform: translateY(-50%) scale(1);
        }
    }
}
[dir="rtl"] {
    .project-status::before {
        inset-inline-start: unset;
        inset-inline-end: 88px;
    }

    .task-list {
        li {
            &:hover {
                .task-icon {
                    transform: scale(1.3);
                    inset-inline-end: 24px;
                }
            }
        }

        &::before {
            inset-inline-start: unset;
            inset-inline-end: 15px;
        }

        .task-icon {
            inset-inline-start: unset;
            inset-inline-end: 10px;
        }
    }
}

@media(max-width: 576px) {
    .project-status::before {
        inset-block-start: 67px;
        inset-inline-start: 27px;
    }
    .project-details-title {
        font-size: 15px !important;
    }
}

.line-height-5 {
    line-height: 1.8 !important;
}

.task-list {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0px 0 0;

    &:before {
        content: "";
        position: absolute;
        inset-block-start: 9px;
        inset-block-end: 0;
        height: 84%;
        inset-inline-start: 15px;
        border-inline-start: 1px solid $default-border;
    }

    li {
        position: relative;
        min-height: 67px;
        padding-inline-start: 55px;

        &:last-child:after {
            display: none;
        }
        &:hover .task-icon,
        &:focus .task-icon {
            transform: scale(1.3);
            inset-inline-start: 24px;
        }
        &:hover .task-icon::before,
        &:focus .task-icon::before {
            inset-inline-start: -5px;
            display: block;
        }

        .task-icon {
            position: absolute;
            inset-inline-start: 10px;
            inset-block-start: 5px;
            border-radius: 50%;
            padding: 2px;
            width: 12px;
            height: 12px;
            z-index: 2;
            transition: all ease 0.2s;

            &::before {
                content: "";
                position: absolute;
                width: 5px;
                height: 1px;
                inset-block-start: 5px;
                background: $default-border;
                display: none;
            }
        }
    }
}

.w-icn {
    width: 1.45rem !important;
}

.project-files-table {
    tr{
        td{
            font-weight: normal !important;
        }
    }
}

.project-file-upload {
    .dropify-wrapper {
        height: 150px;
    }
}

.stars-main {
    i {
        color: $text-muted;
        font-size: 17px;
        cursor: pointer;
        opacity: 0.5;
        &.active {
            color: $primary !important;
        }
    }
    cursor: pointer;
    color: $text-muted;
    &.active {
        color: $primary !important;
    }
}

.no-date-checkbox, .no-taskEnd-checkbox {
    align-self: flex-end;
    margin-block-end: 12px;
}
#editor,
#editor2 {
    height: auto;
}

.project-menu {
    a {
        color: $default-text-color;
        &:hover {
            color: $primary !important;
        }
    }
    .nav-link:focus {
        color: $primary !important;
    }
    .nav-link.active {
        color: $white !important;
    }
}
.hidden {
    display: none;
}

.projects-nav-tabs {
    ul {
        li {
            a {
                display: flex;
                justify-content: center;
                min-width: 6.8rem !important;
            }
        }
    }
}

.overview-list {
    .list-indicator {
        fill: $text-muted !important;
    }
}

.project-tickets-main,
.project-timetracking-main,
.project-tasks-main {
    th {
        &:nth-child(6) {
            min-width: 150px;
        }
    }
}

.rotatable-icon {
    transition: transform 0.3s ease;
}
.rotated {
    transform: rotate(-180deg);
}