.authentication {
  min-height: 100vh;
  .authentication-brand {
    &.desktop-logo {
      display: block;
    }
    &.desktop-dark {
      display: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .swiper-pagination-bullet {
    opacity: 0.1;
  }
  .swiper-pagination-bullet-active {
    opacity: 0.5;
  }
  .google-svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-inline-end: 0.5rem;
  }
  &.coming-soon,
  &.under-maintenance {
    .authentication-cover {
      background-image: url("../images/media/media-19.jpg");
      .aunthentication-cover-content {
        width: 100%;
        height: 100%;
        padding: 3rem;
        backdrop-filter: none;
        background-color: $custom-white;
        &:before,
        &:after {
          display: none;
        }
        .coming-soon-time {
          border: 2px dashed $black-1;
        }
        .authentication-brand {
          width: 50px;
          height: 50px;
          border: 0;
        }
      }
    }
  }
  .coming-soom-image-container,
  .under-maintenance-image-container {
    img {
      width: 100%;
      height: auto;
    }
  }
  .authentication-cover {
    background-image: url("../images/media/media-63.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      inset-inline-start: 0;
      inset-inline-end: 0;
      inset-block-start: 0;
      inset-block-end: 0;
      background-color: $primary-02;
    }
    .aunthentication-cover-content {
      width: 70%;
      height: 37.5rem;
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(1.875rem);
      padding: 2rem;
      position: relative;
      img {
        margin: 0 auto;
        width: 13.75rem;
        height: 13.75rem;
      }
    }
  }
  &.authentication-basic {
    .desktop-dark {
      display: none;
    }
    .desktop-logo {
      display: block;
    }
  }

  .form-control-lg {
    padding: 0.5rem 2.5rem !important;
  }
  #four,
  #one,
  #three,
  #two {
    padding: 0.5rem !important;
  }
}
[data-theme-mode="dark"] {
  .authentication {
    &.authentication-basic {
      .desktop-dark {
        display: block;
      }
      .desktop-logo {
        display: none;
      }
    }
    .authentication-brand {
      &.desktop-logo {
        display: none;
      }
      &.desktop-dark {
        display: block;
      }
    }
  }
}
.authentication {
  .desktop-logo,
  .desktop-dark {
    height: 2rem;
    line-height: 2rem;
  }
}

.sign-in-basic-page {
  background-image: url("../images/media/bg-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    background-color: $primary-06;
    z-index: -1;
  }
}
.error-pages {
  background-image: url("../images/pngs/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    background-color: $primary-06;
    z-index: -1;
  }
}

.input-box {
  position: relative;

  input {
    padding-inline-start: 2.5rem;
  }

  .authentication-input-icon {
    position: absolute;
    inset-block-start: 9px;
    inset-inline-start: 17px;
    z-index: 5;
  }
}
.under-maintenance-time {
  background-color: $custom-white;
  box-shadow: $box-shadow;
}
