.revenue-item-list {
  position: relative;
  margin-block-end: 18px;
  padding-inline-start: 15px;
  
}

.revenue-item-icon {
  border-radius: 50px;
  padding: 2px;
  width: 10px;
  height: 10px;
  z-index: 2;
}

.revenue-item-list,
.task-list-table {
  list-style-type: none;
  li {
    position: relative;
  }
}

.daily-item-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0px 0 0;
  li {
    position: relative;
    padding-inline-start: 55px;
    min-height: 73px;
  }
}

.daily-item-list li .daily-item-icon::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 1px;
  inset-block-start: 5px;
  background: $default-border;
  display: none;
}

.daily-item-list li:hover .daily-item-icon::before {
  inset-inline-start: -5px;
  display: block;
}

.daily-item-list:before {
  content: "";
  position: absolute;
  inset-block-start: 9px;
  inset-block-end: 0;
  height: 82%;
  inset-inline-start: 15px;
  border-inline-start: 1px solid $default-border;
}

.daily-item-list li:hover .daily-item-icon, .daily-item-list li:focus .daily-item-icon {
  transform: scale(1.3);
  inset-inline-start: 24px;
}

.daily-item-icon {
  position: absolute;
  inset-inline-start: 10px;
  inset-block-start: 5px;
  border-radius: 50%;
  padding: 2px;
  width: 12px;
  height: 12px;
  z-index: 2;
  transition: all ease 0.2s;
}

.tl-blog {
  display: flex;
  position: relative;
  min-height: 70px;
  cursor: pointer;
}
.tl-blog .tl-img {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;

}

.tl-blog .tl-details {
  margin-inline-start: 15px;
  width: 100%;
  margin-block-start: 6px;
}

.tl-blog .tl-title-main {
  transition: all ease 0.2s;
}

.tl-blog:before {
  content: "";
  position: absolute;
  inset-block-start: 31px;
  inset-block-end: 0;
  height: 40px;
  inset-inline-start: 15px;
  width: 3px;
  border-inline-start: 1px solid $default-border;
}
.tl-blog:last-child::before {
  content: none;
}
.tl-blog.primary:hover .tl-title-main {
  color: $primary;
}
.tl-blog.secondary:hover .tl-title-main {
  color: $secondary;
}
.tl-blog.teritary:hover .tl-title-main {
  color: $info;
}
.tl-blog.custom-yellow:hover .tl-title-main {
  color: $warning;
}

.todo-blog {
  display: flex;
  position: relative;
  min-height: 70px;
  margin-inline-start: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .todo-img {
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: relative;
      inset-block-start: 0;
      inset-inline-start: 0;
      height: 18px;
      width: 18px;
      background-color:$custom-white;
      border-radius: 5px;
      border: 1px solid $default-border;
    }
  }

  .todo-details {
    margin-inline-start: 15px;
    width: 100%;
    margin-block-start: 6px;
  }

  .primary:before {
    background: $primary;
  }
  .secondary:before {
    background: $secondary;
  }
  .teritary:before {
    background: $info;
  }
  .custom-yellow:before {
    background: $warning;
  }
  .todo-blog.primary:before {
    background: $primary;
  }
}
.todo-blog.custom-yellow .todo-img input:checked~.checkmark {
  background-color: $warning;
  border-color: $warning;
}
.todo-blog.secondary .todo-img input:checked~.checkmark {
  background-color: $secondary;
  border-color: $secondary;
}
.todo-blog.primary .todo-img input:checked~.checkmark {
  background-color: $primary;
  border-color: $primary;
}
.todo-blog.teritary .todo-img input:checked~.checkmark {
  background-color: $info;
  border-color: $info;
}
.todo-blog .todo-img .checkmark::after {
  content: "";
  position: absolute;
  inset-inline-start: 5px;
  inset-block-start: 12%;
  width: 0.4rem;
  height: 10px;
  border: solid $custom-white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.todo-blog:before {
  content: "";
  position: absolute;
  inset-block-start: 10px;
  inset-block-end: 0;
  height: 26px;
  inset-inline-start: -6px;
  width: 3px;
}
.todo-blog.primary:before {
  background: $primary;
}
.todo-blog.secondary:before {
  background: $secondary;
}
.todo-blog.teritary:before {
  background: $info;
}
.todo-blog.custom-yellow:before {
  background: $warning;
}

.status-indicator {
  .dropdown-item:hover {
    color: $white !important;
    background-color: $primary !important;
  }
}

.status-indicator::before {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  inset-block-start: 46%;
  inset-inline-start: 5px;
  transform: translate(50%, -41%);
}
.status-indicator.on-hold::before {
  background-color: $warning;
}
.status-indicator.completed::before {
  background-color: $primary;
}
.status-indicator.in-progress::before {
  background-color: $info;
}


[dir="rtl"] {
  .status-indicator::before {
    transform: translate(-50%,-41%);
  }
}

#chartD {
  .apexcharts-grid line,
  .apexcharts-grid-borders line,
  .apexcharts-xaxis line {
    display: block !important;
  }
}